import React from "react";
import { PageContainer } from "styles/global.style";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { BeforeAfterContainer } from "components/ui/extended/BeforeAfter/index.style";

const BeforeAfter: React.FunctionComponent<{
  firstImgSrc: string;
  secondImgSrc: string;
}> = ({ firstImgSrc, secondImgSrc }) => {
  return (
    <PageContainer>
      <BeforeAfterContainer>
        <ReactCompareSlider
          changePositionOnHover
          position={81}
          itemOne={
            <ReactCompareSliderImage
              src={firstImgSrc}
              alt="Before Customerly"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={secondImgSrc}
              alt="After Customerly"
            />
          }
        />
      </BeforeAfterContainer>
    </PageContainer>
  );
};

export default BeforeAfter;
