import styled from "styled-components";

const BeforeAfterContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    height: min-content;
    border-radius: 16px;
  }

  .__rcs-handle-line {
    background-color: ${(props) => props.theme.accentColor} !important;
    width: 6px !important;
  }

  .__rcs-handle-button {
    color: ${(props) => props.theme.accentColor} !important;
    box-shadow: 0 0 7px ${(props) => props.theme.accentColor} !important;

    > div {
      color: ${(props) => props.theme.accentColor} !important;
    }
  }
`;

export { BeforeAfterContainer };
