import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import PlainHeader from "components/ui/base/PlainHeader";
import NumberSteps from "components/ui/base/NumberSteps";
import BeforeAfter from "components/ui/extended/BeforeAfter";
import FullWidthImage from "components/ui/base/FullWidthImage";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import Customers from "components/ui/extended/Customers";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";
import Awards from "components/ui/extended/Awards";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";

interface AICustomerServicePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    beforeImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    afterImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: AICustomerServicePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ withCta: true, withMenu: false }}
    >
      <SEO
        title={t("pages.ai.seo.title")}
        description={t("pages.ai.seo.description")}
        image={{
          relativePath: "meta/customerly-ai.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.ai.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.ai.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.ai.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.ai.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.ai.title")}
        description={t("pages.ai.description")}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        ctaRegister={{ show: true }}
        ctaGetDemo={{
          show: true,
          primary: true,
        }}
      />
      <BeforeAfter
        firstImgSrc={
          data.beforeImage.childImageSharp.gatsbyImageData.images.fallback!.src
        }
        secondImgSrc={
          data.afterImage.childImageSharp.gatsbyImageData.images.fallback!.src
        }
      />

      <Customers />

      <NumberSteps
        title={t("pages.ai.steps.title")}
        elements={Array.from(Array(4).keys()).map((i) => {
          return {
            title: t(`pages.ai.steps.${i + 1}.title`),
            description: t(`pages.ai.steps.${i + 1}.description`),
          };
        })}
      />

      <ImageWithDescription
        title={t("pages.ai.1.title")}
        description={t("pages.ai.1.subtitle")}
        image={{
          relativePath: "pages/ai/ai-assistant.jpg",
          alt: t("pages.ai.1.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FullWidthImage
        title={t("pages.ai.2.title")}
        description={t("pages.ai.2.subtitle")}
        descriptionMaxWidth={700}
        image={{
          relativePath: "pages/ai/ai-mission-setup.jpg",
          alt: t("pages.ai.2.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.ai.3.title")}
        description={t("pages.ai.3.subtitle")}
        image={{
          relativePath: "pages/ai/ai-multilanguage.jpg",
          alt: t("pages.ai.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <TestimonialWithImageLaurentiu />
      <Awards />
      <RegisterForCustomerService />
    </GlobalWrapper>
  );
};

export const AICustomerServicePagePropsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-ai.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beforeImage: file(
      relativePath: { eq: "pages/ai/before.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    afterImage: file(
      relativePath: { eq: "pages/ai/after.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
